import React from "react";

const DataTable = ({ title, headers, data, noDataMessage }) => {
  return (
    <div className="mt-5">
      <h3>{title}</h3>
      <div className="table-responsive">
      <table className="table table-bordered table-striped">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <tr key={index}>
                  {headers.map((header, i) => (
                    <td key={i}>
                      {header === "Equipment Provider"
                        ? row[header] // Keep `null` if `Equipment Provider` is null
                        : row[header] !== undefined
                        ? row[header]
                        : 0} {/* Default to 0 for other keys */}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length} className="text-center">
                  {noDataMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
