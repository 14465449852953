import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import DataTable from "./DataTable"; // Import the DataTable component

const InvoicesCount = () => {
  const [kpis, setKpis] = useState({
    equipment_provider_data: [],
    work_type_data: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchInvoicesCount = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${baseUrl}/api/invoices-count/`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setKpis(data);
      setError(null);
    } catch (err) {
      console.error("Error fetching invoices count:", err);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoicesCount();

    const interval = setInterval(() => {
      fetchInvoicesCount();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        heading="WorkOrder Invoices Count"
        button1Label="Dashboard"
        button1Link="/"
        button2Label="KPIs"
        button2Link="/kpi"
      />

      <div className="mx-5 mt-5">
        {error && <div className="text-danger text-center">Error: {error}</div>}

        {/* Equipment Provider Table */}
        <DataTable
          title="Equipment Provider Data"
          headers={[
            "Equipment Provider",
            "Data Entry into Provider's System",
            "Generate Assignments",
            "Invoice & Payable Created in QB",
          ]}
          data={kpis.equipment_provider_data.map((provider) => ({
            "Equipment Provider": provider.Equipment_Provider,
            "Data Entry into Provider's System": provider["Data Entry into Provider's System"],
            "Generate Assignments": provider["Generate Assignments"],
            "Invoice & Payable Created in QB": provider["Invoice & Payable Created in QB"],
          }))}
          noDataMessage="No data available"
        />

        {/* Work Type Table */}
        <DataTable
          title="WorkType Data"
          headers={[
            "WorkType",
            "Data Entry into Provider's System",
            "Generate Assignments",
            "Invoice & Payable Created in QB",
          ]}
          data={kpis.work_type_data.map((workorder) => ({
            WorkType: workorder.Work_Type,
            "Data Entry into Provider's System": workorder["Data Entry into Provider's System"],
            "Generate Assignments": workorder["Generate Assignments"],
            "Invoice & Payable Created in QB": workorder["Invoice & Payable Created in QB"],
          }))}
          noDataMessage="No data available"
        />
      </div>
    </div>
  );
};

export default InvoicesCount;
