// Modal.js
import React from "react";

const Modal = ({ selectedRow, setSelectedRow }) => {
  return (
    selectedRow && (
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
        aria-labelledby="detailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="detailModalLabel">
                Details
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setSelectedRow(null)}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(selectedRow).map((key) => (
                    <tr key={key}>
                      <td>{key.replace(/_/g, " ")}</td>
                      <td>{selectedRow[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setSelectedRow(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
